import Store, { withStore, useStore } from 'stores';
import expose from 'helpers/expose';
import queries, { ClaimableEntityType, FollowableEntityType, WebUserEntitiesQuery } from 'queries';

type UserEntityType = WebUserEntitiesQuery['currentUser']['userEntities'][number];

// can't reuse standard type checks do nesting under `entity`
type ExtractUserEntityType<TypeName extends UserEntityType['entity']['__typename']> = Omit<UserEntityType, 'entity'> & {
  entity: Extract<UserEntityType['entity'], { __typename: TypeName }>;
};

export type UserEntitySchoolType = ExtractUserEntityType<'School'>;
export type UserEntityTeamType = ExtractUserEntityType<'Team'>;

export type FollowableUserEntityType = UserEntitySchoolType | UserEntityTeamType;

function isFollowableUserEntityType(userEntity: UserEntityType): userEntity is FollowableUserEntityType {
  return ['Team', 'School'].includes(userEntity.entity.__typename);
}

interface UserEntitiesStoreState {
  userEntities: FollowableUserEntityType[];
  isResolved: boolean;
}

const userEntitiesStore = new Store<UserEntitiesStoreState>({ userEntities: [], isResolved: false }, 'userEntities');

export const WithUserEntities = withStore(userEntitiesStore);

export function useUserEntities() {
  return useStore(userEntitiesStore);
}

export function claimEntity(entityType: ClaimableEntityType, entityId: string) {
  return queries
    .webClaimEntityMutation({
      entity: {
        id: entityId,
        source: 'web',
        type: entityType
      }
    })
    .then(({ claimEntity }) => {
      if (!isFollowableUserEntityType(claimEntity)) return;

      const userEntities = [...userEntitiesStore.state.userEntities, claimEntity];
      userEntitiesStore.setState({ userEntities });
      return userEntities;
    });
}

export function followEntity(entityType: FollowableEntityType, entityId: string) {
  return queries
    .webFollowEntityMutation({
      entity: {
        type: entityType,
        id: entityId,
        source: 'web'
      }
    })
    .then(({ followEntity }) => {
      if (!isFollowableUserEntityType(followEntity)) return;

      const userEntities = [...userEntitiesStore.state.userEntities, followEntity];
      userEntitiesStore.setState({ userEntities });
      return userEntities;
    });
}

export function unfollowEntity(userEntity: FollowableUserEntityType) {
  queries.webUnfollowUserEntityMutation({ id: userEntity.id }).then(({ unfollowEntity: { id } }) => {
    const userEntities = userEntitiesStore.state.userEntities.filter(userEntity => userEntity.id !== id);
    userEntitiesStore.setState({ userEntities });
    return userEntities;
  });
}

export function fetchUserEntities(force = false): Promise<FollowableUserEntityType[]> {
  return new Promise((resolve, reject) => {
    if (userEntitiesStore.state.isResolved && !force) {
      resolve(userEntitiesStore.state.userEntities);
    } else {
      queries
        .webUserEntitiesQuery()
        .then(({ currentUser }) => {
          const userEntities = currentUser.userEntities.filter(isFollowableUserEntityType);

          userEntitiesStore.setState({ userEntities, isResolved: true });
          resolve(userEntities);
        })
        .catch(reject);
    }
  });
}

export function clearUserEntities() {
  userEntitiesStore.setState({ userEntities: [], isResolved: false });
}

expose('userEntitiesStore', userEntitiesStore);

export default userEntitiesStore;
